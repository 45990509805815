<template>
    <Modal class="application-edit-form size-xxl" v-if="opened" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            Editace žádosti o přijetí dítěte do MŠ
        </template>
        <template v-slot:body>
            <BaseForm 
                :form_class="''"
                @closeForm="closeForm()" 
                :deleteButton="false" 
            >
                    
            </BaseForm>
        </template>
    </Modal>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { computed, watchEffect } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ApplicationEditForm",
    components: {
        BaseForm,
        Modal
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
    },
    setup(props,context){       
        const i18n = useI18n();          
        const { data,setRows,formInit,formActions,code,store_action } = useFormData();
        formInit();


        const store = useStore();
        
        const schools = computed(() => store.getters['school/getItems']);
        const schoolOptions = computed(() => {
            let array = [];
            for(let school of schools.value){
                array.push({
                    title: school.short_name,
                    code: school.item_key
                })
            }
            return array;
        })
            
        const rows = [
            {
                labels: [i18n.t('enrollment.zadost_podana_na_zarizeni')],
                items: [
                    {
                        title: i18n.t('enrollment.zadost_podana_na_zarizeni'),
                        type: 'label',
                        content: () => {
                            return data.value.schoolApplied.fullname;
                        },
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.zadost_vyrizuje_na_zarizeni')],
                labels_class: ['strong'],
                items: [
                    {
                        title: i18n.t('enrollment.zadost_vyrizuje_na_zarizeni'),
                        type: 'select',
                        key: "schoolProcessingCode",
                        class: "strong",
                        options: () => schoolOptions.value,
                    },
                    {
                        title: i18n.t('enrollment.reg_number'),
                        type: 'label',
                        content: () => {
                            return i18n.t('enrollment.reg_number')+": "+data.value.reg_number;
                        },
                        class: "w-stretch strong aligh-right"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.cislo_jednaci')],
                items: [
                    {
                        title: i18n.t('enrollment.cislo_jednaci'),
                        type: 'text',
                        key: 'cislo_jednaci',
                        class: ""
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.prijmeni_jmeno')],
                items: [
                    {
                        title: i18n.t('enrollment.prijmeni'),
                        label: i18n.t('enrollment.prijmeni'),
                        type: 'text',
                        key: 'prijmeni_ditete',
                        class: "w-stretch",
                        required: true,
                    },
                    {
                        title: i18n.t('enrollment.jmeno'),
                        label: i18n.t('enrollment.jmeno'),
                        type: 'text',
                        key: 'jmeno_ditete',
                        class: "w-stretch",
                        required: true,
                    },
                ]
            },
            {
                labels: [i18n.t('enrollment.narozeni_stari_stat_dochazka')],
                items: [
                    {
                        title: i18n.t('enrollment.datum_narozeni'),
                        label: i18n.t('enrollment.datum_narozeni').toLowerCase(),
                        type: 'text',
                        key: 'datumNarozeni',
                        class: "",
                        required: true,
                    },
                    {
                        title: i18n.t('enrollment.stari'),
                        label: i18n.t('enrollment.stari').toLowerCase(),
                        type: 'info',
                        key: 'stariDitete',
                        class: "w-fit"
                    },
                    {
                        title: i18n.t('enrollment.pohlavi'),
                        label: i18n.t('enrollment.pohlavi'),
                        type: 'select',
                        key: 'pohlavi',
                        class: "",
                        required: true,
                        options: () => [
                            {
                                title: 'chlapec',
                                code: 10,
                            },
                            {
                                title: 'dívka',
                                code: 20,
                            }
                        ]
                    },
                    {
                        title: i18n.t('enrollment.statni_prislusnost'),
                        label: i18n.t('enrollment.statni_prislusnost'),
                        type: 'text',
                        key: 'statni_prislusnost',
                        class: ""
                    },
                    {
                        title: i18n.t('enrollment.attendance'),
                        label: i18n.t('enrollment.attendance').toLowerCase(),
                        type: 'select',
                        key: "dochazka",
                        class: "w-stretch",
                        required: true,
                        options: () => [
                            {
                                title: 'celodenní',
                                code: 10,
                            },
                            {
                                title: 'polodenní',
                                code: 20,
                            }
                        ]
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.residence')],
                items: [
                    {
                        label: i18n.t('enrollment.street'),
                        title: i18n.t('enrollment.street'),
                        type: 'text',
                        key: "bydliste_ulice",
                        class: "w-stretch",
                        required: true,
                    },
                    {
                        label: i18n.t('enrollment.zip_code'),
                        title: i18n.t('enrollment.zip_code'),
                        type: 'text',
                        key: "bydliste_PSC",
                        required: true,
                        rules: {
                            lengthMin: 5,
                            lenghtMax: 10,
                        }
                    },
                    {
                        label: i18n.t('enrollment.city'),
                        title: i18n.t('enrollment.city'),
                        type: 'text',
                        key: "bydliste_mesto",
                        class: "",
                        required: true,
                    },
                    {
                        label: i18n.t('enrollment.spadova_obec').toLowerCase(),
                        title: i18n.t('enrollment.spadova_obec'),
                        type: 'checkbox',
                        key: "priznak_spadova_obec",
                        class: "w-xs",
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.siblings')],
                items: [
                    {
                        label: i18n.t('enrollment.jmena_sourozencu'),
                        title: i18n.t('enrollment.jmena_sourozencu'),
                        type: 'text',
                        key: "sourozenci",
                        class: "w-stretch"
                    },
                    {
                        label: i18n.t('enrollment.sourozenci_short').toLowerCase(),
                        title: i18n.t('enrollment.sourozenec'),
                        type: 'checkbox',
                        key: "priznak_sourozenci",
                        class: "w-xs",
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.representative')],
                items: [
                    {
                        label: i18n.t('enrollment.representative_name'),
                        title: i18n.t('enrollment.representative_name'),
                        type: 'text',
                        key: "zastupce_jmeno",
                        class: "w-l",
                        required: true,
                    },
                    {
                        label: i18n.t('enrollment.representative_residence'),
                        title: i18n.t('enrollment.representative_residence'),
                        type: 'text',
                        key: "zastupce_bydliste",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.contact')],
                items: [
                    {
                        label: i18n.t('enrollment.phone'),
                        title: i18n.t('enrollment.phone'),
                        type: 'text',
                        key: "zastupce_telefon",
                        class: "w-l"
                    },
                    {
                        label: i18n.t('enrollment.email'),
                        title: i18n.t('enrollment.email'),
                        type: 'text',
                        key: "zastupce_email",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.dalsi_informace')],
                items: [
                    {
                        title: i18n.t('enrollment.dalsi_informace'),
                        type: 'info',
                        key: "poznamka",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.podal_zapsal')],
                items: [
                    {
                        title: i18n.t('enrollment.zadost_podal'),
                        label: i18n.t('enrollment.zadost_podal'),
                        type: 'info',
                        key: "submitted_by",
                        class: "w-stretch",
                    },
                    {
                        title: i18n.t('enrollment.zadost_zapsal'),
                        label: i18n.t('enrollment.zadost_zapsal'),
                        type: 'info',
                        key: "created_by",
                        class: "w-stretch",
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.poznamky')],
                items: [
                    {
                        title: i18n.t('enrollment.poznamky'),
                        type: 'textarea',
                        key: "poznamka_private",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.datumy_rizeni')],
                items: [
                    {
                        title: i18n.t('enrollment.zahajeni_rizeni'),
                        label: i18n.t('enrollment.zahajeni_rizeni'),
                        type: 'text',
                        key: "zahajeniSpravnihoRizeni",
                        class: "w-stretch"
                    },
                    {
                        title: i18n.t('enrollment.lhuta'),
                        label: i18n.t('enrollment.lhuta'),
                        type: 'text',
                        key: "lhutaNaVyrizeni",
                        class: "w-stretch"
                    },
                    {
                        title: i18n.t('enrollment.datum_rozhodnuti'),
                        label: i18n.t('enrollment.datum_rozhodnuti'),
                        type: 'text',
                        key: "datumRozhodnuti",
                        class: "w-stretch"
                    },
                    {
                        title: i18n.t('enrollment.nabyti_pravni_moci'),
                        label: i18n.t('enrollment.nabyti_pravni_moci'),
                        type: 'text',
                        key: "datumNabyti",
                        class: "w-stretch"
                    },
                ]
            },
            {
                labels: [i18n.t('enrollment.stav_zadosti')],
                items: [
                    {
                        title: i18n.t('enrollment.stav_zadosti'),
                        type: 'select',
                        key: "status",
                        class: "w-l",
                        options: () => [
                            {
                                title:  i18n.t("enrollment.nova_zadost"),
                                code: 0,
                            },
                            {
                                title: i18n.t("enrollment.zadost_v_rizeni"),
                                code: 1,
                            },
                            {
                                title: i18n.t("enrollment.dite_prijato"),
                                code: 2,
                            },
                            {
                                title: i18n.t("enrollment.dite_neprijato"),
                                code: 3,
                            },
                            {
                                title: i18n.t("enrollment.vyrazena_zadost"),
                                code: 9,
                            },
                        ]
                    }
                ]
            },
        ];

        watchEffect(() => {
            // console.log(props.baseData)
            if(props.baseData === null || !props.opened){
                return;
            }
            data.value = Object.assign({},props.baseData);
            code.value = props.baseData.code;
            setRows(rows);
            // store_action.value = 'content/updateItem';
        });

        
        const closeForm = () => {
            context.emit('close');
        }

        // const store = useStore();
        
        // formActions.value.afterSubmit = () => {
        //     context.emit('updateValues',data.value)
        //     // closeForm()
        // };
        // // formActions.value.cancelForm = () =>  closeForm();

    
        store_action.value = 'application/updateItem';
        formActions.value.afterSubmit = () => closeForm();
            
        formActions.value.cancelForm = () =>  closeForm();

        return {
            closeForm,
            data,
        }

    }

}
</script>

<style scoped>

</style>